$(document).ready(function() {

    //vars
    var btn_menu = $('.btn--menu-js'),
        hero_banner = $('.hero--banner-js');
        hero_statistics = $('.hero--statistics-js');
        hero_texts = $('.hero--texts-js');
    //end vars


    //menu
    if (btn_menu.length) {
        btn_menu.on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $('html').toggleClass('body-menu-active');
        });
    }
    //end menu


    //hero banner
    if (hero_banner.length) {
        hero_banner.on('init', function(event, slick){
            slick.$dots.addClass('hero--dots');
        });
        hero_banner.slick({
            fade: true,
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 4000
        });   
    }
    //end hero banner

    //scroll minus - scroll to
    if($(window).width() <= 767) {
        var scrollminus = 50;
    } else {
        var scrollminus = 20;
    }
    //end scroll minus - scroll to
    
    //scroll to categories
    function scrollTabs() {
        $('html, body').animate({
            scrollTop: $('.categories-js').offset().top -scrollminus + 'px'
        }, 500);
    }
    $('.scrollto-cat-js').on('click', function() {
        scrollTabs();
    });
    //end scroll to categories
    
    //hero statistics
    function heroStatistics() {
        hero_statistics.on('init', function(event, slick){
            slick.$dots.addClass('hero--dots');
        });
        hero_statistics.slick({
            fade: true,
            arrows: false,
            dots: true,
        });
    }
    if (hero_statistics.length) {    
        //$(window).on('load', function() {
            setTimeout(function() {
                heroStatistics();
            }, 10)

        //});
    }
    //end hero statistics

    //drop down menu mobile
    if ($('.wrapper-dropdown-xs').length) {
        if($(window).width() <= 767) {
            function DropDown(el) {
                this.dd = el;
                this.placeholder = this.dd.children('span');
                this.opts = this.dd.find('ul.dropdown > li');
                this.val = '';
                this.index = -1;
                this.initEvents();
            }
            DropDown.prototype = {
                initEvents : function() {
                    var obj = this;
        
                    obj.dd.on('click', function(event){
                        $(this).toggleClass('active');
                        return false;
                    });
        
                    obj.opts.on('click',function(){
                        var opt = $(this);
                        obj.val = opt.text();
                        obj.index = opt.index();
                        obj.placeholder.text(obj.val);
                    });
                },
                getValue : function() {
                    return this.val;
                },
                getIndex : function() {
                    return this.index;
                }
            }
        
            $(function() {
        
                var dd = new DropDown( $('#ddxs') );
        
                $(document).click(function() {
                    // all dropdowns
                    $('.wrapper-dropdown-xs').removeClass('active');
                });
        
            });
        }
    }
    //end drop down menu mobile


    //tabs group home
    if ($('.tabs-group-js').length) {
        $('.tabs-group-js').each(function() {

            var maintabs_lists = $('li', $(this)),
                maintabs_anchors = $('a', maintabs_lists),
                maintabs, selector = '';

            maintabs_anchors.each(function(index) {

                selector += ($(this).attr('href') != '#' ? (selector !== '' ? ', ' : '') + $(this).attr('href') : '');

                $(this).on('click', function(e) {
                    e.preventDefault();
                    hero_statistics.slick('unslick');
                    document.location.hash = $(this).attr('href');
                    maintabs_lists.removeClass('current-menu-item');
                    $(maintabs_lists[index]).addClass('current-menu-item');
                    maintabs.css('display', 'none').removeClass('active');
                    $(maintabs[index]).removeClass('hidden').css('display', 'block').addClass('active');
                    heroStatistics();
                });

            });

            maintabs = $(selector);

            maintabs.easyResponsiveTabs({
                type: 'vertical',
                width: 'auto',
                fit: true,
                closed: 'accordion',
                activate: function() {
                    document.location.hash = '';
                }
            });

            maintabs.css('display', 'none').removeClass('active');
            $(maintabs[0]).css('display', 'block').addClass('active');

            //refresh sau deschide tab nou cu o anumita categorie activa
            if (document.location.hash !== '') {
                setTimeout(function() {
                    var active_tab = document.location.hash.replace(/[0-9]+$/, '');
                    //console.log(active_tab);
                    if ($(active_tab).length > 0) {
                        scrollTabs();
                        maintabs_lists.removeClass('current-menu-item');
                        maintabs.css('display', 'none').removeClass('active');
                        $('[href="' + active_tab + '"]', maintabs_lists).parents('li').addClass('current-menu-item');
                        $(active_tab).removeClass('hidden').css('display', 'block').addClass('active'); 
                        $('#ddxs span').text($('[href="' + active_tab + '"]', maintabs_lists).text());
                    }
                }, 1);
            }

        });
    }
    //end tabs group home



    //hero texts
    if (hero_texts.length) {
        hero_texts.slick({
            arrows: true,
            prevArrow: $('.btn--hero-prev-js'),
            nextArrow: $('.btn--hero-next-js'),
        });
    }
    //end hero texts


    // $.fn.randomize = function (selector) {
    //     var $elems = selector ? $(this).find(selector) : $(this).children(),
    //         $parents = $elems.parent();
    
    //     $parents.each(function () {
    //         $(this).children(selector).sort(function (childA, childB) {
    //             // * Prevent last slide from being reordered
    //             if($(childB).index() !== $(this).children(selector).length - 1) {
    //                 return Math.round(Math.random()) - 0.5;
    //             }
    //         }.bind(this)).detach().appendTo(this);
    //     });
    
    //     return this;
    // };
    
    // hero_texts.randomize().slick();



    //read more
    if ($('.btn--more-js').length) {
        $('body').on('click', '.btn--more-js', function() {
            $(this).parent().find('.despre--texts-js').toggleClass('show');
            $(this).toggleClass('show');
            console.log('expand');
        });
    }
    
    
    //tooltip
    if ($('[data-toggle="tooltip"]').length) {
        $('[data-toggle="tooltip"]').tooltip();
    }

    //endorseri
    var grids_team = $('.endorseri-js'),
        member = $('.endorser__item'),
        master_member_wrap = $('.endorser__details-js'),
        master_member_wrap_details = $('.endorser__details__inner-js');

    grids_team.each(function(i, el) {
        var grid = $(el),
            grid_members = grid.find('.endorser__item');
        
        grid_members.on('click', function(e){
            var member_this = $(this),
                details = member_this.find('.endorser__item__details-js'),
                index = grid_members.index(member_this),
                destination = grid_members.eq(0),
                items_per_row;
            e.preventDefault();
            
            member.removeClass('active');
            member_this.addClass('active');

            if ($(window).width() < 576) {
                destination = index;
            }
            else if ( $(window).width() > 575 && $(window).width() < 767) {
                items_per_row = 3;
                destination = Math.floor(index/items_per_row) * items_per_row + items_per_row - 1;
            }
            else {
                items_per_row = 4;
                destination = Math.floor(index/items_per_row) * items_per_row + items_per_row - 1;
            }

            console.log('index', index);
            console.log('dest', destination);
            console.log('cate', items_per_row);
            console.log('grid members', grid_members);
            var details_position = destination < grid_members.length ? destination : grid_members.length - 1;
            master_member_wrap.addClass('d-none').removeClass('d-none').insertAfter( grid_members.eq(details_position) );
            master_member_wrap_details.html(details.html());
            $('body, html').animate({scrollTop: member_this.offset().top - 20 }, 250);
            
        });
    });


    //set cookies
    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }


    //popup split screen
    var popupSplit = $('.popup-full--js'),
        btnHideSplit = $('.btn-hide-popup--js'),
        siteContentHome = $('body.home .site-content');
    if(popupSplit.length) {
        popupSplit.removeClass('d-none');
        btnHideSplit.on('click', function(e) {
            popupSplit.addClass('d-none');
            siteContentHome.removeClass('d-none');
            setCookie('popupSplitHide', 'yes', 1);
        });
    }

    //cookie popup split is set
    var cookieSplit = getCookie('popupSplitHide');

    if(cookieSplit) {
        popupSplit.addClass('d-none');
    } else {
        siteContentHome.addClass('d-none');
    }
    

});
